import React, {useContext, useState, useEffect} from 'react';
import Header from '../../layout/Header/Header';
import AuthContext from "../../contexts/authContext";
import Button from "../../components/bootstrap/Button";
import OffCanvas, {OffCanvasBody, OffCanvasHeader, OffCanvasTitle} from "../../components/bootstrap/OffCanvas";
import Alert from "../../components/bootstrap/Alert";
import { isAuthenticated } from "../../services/auth";
import api from "../../services/api";
import Dropdown, {
	DropdownMenu,
	DropdownToggle,
} from '../../components/bootstrap/Dropdown';
import Icon from '../../components/icon/Icon';
import showNotification from "../../components/extras/showNotification";
import Popovers from '../../components/bootstrap/Popovers';
import moment from 'moment';

const MINUTE_MS = 60000;

const DashboardHeader = () => {
	const { company } = useContext(AuthContext);
	const [ isOpenNotification, setIsOpenNotification ] = useState(false);
	const [ newNotification, setNewNotification ] = useState(false);
	const [ notifications, setNotifications ] = useState([]);
	const pathLogo = company !== null && company.file_system !== null ? (process.env.REACT_APP_HOST_API + company.file_system.path) : null;
	const styledBtn = {
		color: 'dark',
		hoverShadow: 'default',
		isLight: false,
		size: 'lg',
	};

	const generateQrCode = () => {
		showNotification('Atenção!', 'Estamos gerando seu PDF com o QrCode, por favor aguarde.', 'info', true);
		api.post(`/base/getQrCodeDownloadApp`).then(resp => {
			if (resp.data.erro) {
				showNotification('Atenção!', resp.data.message, 'warning', true);
			} else {
				let a = document.createElement("a");
				a.href = "data:application/octet-stream;base64," + resp.data.pdf;
				a.download = resp.data.name;
				a.click();
				showNotification('Sucesso!', `Pdf gerado com sucesso.`, 'success', true);
			}
		})
	}

	const getExternalScheduleCode = () => {
		api.post('schendule/newExternalScheduleCode').then(resp => {
			if (resp.data.error) {
				showNotification('Atenção!', resp.data.message, 'warning', true);
			} else {
				showNotification('Sucesso!', resp.data.message, 'success', true);
				window.navigator.clipboard.writeText(resp.data.link);
			}
		})
	}

	const copyAndRedirect = (mobile) => {
		showNotification('Sucesso!', `Link de download copiado com sucesso.`, 'success', true);
		let url = '';
		if (mobile === 'GOOGLE') {
			url = 'https://play.google.com/store/apps/details?id=com.linksystem.appbarber';
		} else {
			url = 'https://apps.apple.com/us/app/ei-barber/id1664266768';
		}

		window.navigator.clipboard.writeText(url);
		setTimeout(() => {
			window.open(url, '_blank', 'noreferrer');
		}, 100)
	}

	const getNotifications = () => {
		if (isAuthenticated()) {
			api.put(`/auth/getNotifications`).then(resp => {
				if (resp.data.length > notifications.length) setNewNotification(true);
				else setNewNotification(false);
				setNotifications(resp.data);
			})
		}
	}

	const readNotify = ({ id }) => api
		.post(`/auth/readNotification/${id}`)
		.finally(resp => getNotifications());

	const isInvoicing = company != null && company.plan_date_limit != null && moment(company.plan_date_limit).format('YYYY-MM-DD H:m:s') <= moment().format('YYYY-MM-DD H:m:s');

	const openInvoicing = () => {
		if (company.link_last_invoicing == null) {
			return showNotification('Atenção!', "Sua fatura ainda não esta pronta, avisaremos quando estiver gerada!", 'warning', true);
		}

		return window.open(company.link_last_invoicing, "_blank");
	}

	useEffect(() => {
		getNotifications();
		const interval = setInterval(() => {
			getNotifications();
		}, MINUTE_MS);

		return () => clearInterval(interval);
	}, [])

	return company !== null && (
		<Header>
			<div className='header-left col-md'>
				<div className='row'>
					<div className='col-md-1' style={{alignContent: 'center'}}>
						{
							pathLogo && (
								<>
									<source srcSet={ pathLogo } type='image/svg+xml' />
									<img src={ pathLogo } className='img-fluid img-thumbnail' style={{width: '50px', marginRight: '15px'}}/>
								</>
							)
						}
					</div>

					<div className='col-md-3' style={{alignContent: 'center'}}>
						{ company.fantasy_name } - { company.cnpj_cpf }
					</div>

					<div className='col-md-8' style={{alignContent: 'center'}}>
						{
							company.plan_date_limit === null && (
								<div className='row'>
									<span className='text-danger'>
										Você está utilizando o modo grátis.
									</span>
								</div>
							)
						}
					</div>
				</div>
			</div>

			<div className='header-right col-md-auto'>
				<div className='row g-3'>
					<div className='col-auto'>
						<Dropdown>
							<DropdownToggle hasIcon={false}>
								<Button {...styledBtn} icon='AppSettingsAlt' aria-label='Quick menu' />
							</DropdownToggle>
							<DropdownMenu isAlignmentEnd size='lg' className='py-0 overflow-hidden'>
								<div className='row g-0'>
									<div className='col-12 p-4 d-flex justify-content-center fw-bold fs-5 text-primary border-bottom border-primary bg-lo25-primary'>
										Baixe nosso aplicativo
									</div>

									<a
										style={{'text-decoration': 'none'}}
										className='col-6 p-4 transition-base cursor-pointer bg-light-hover border-end border-bottom border-dark'
										onClick={() => copyAndRedirect('APPLE')}
									>
										<div className='d-flex flex-column align-items-center justify-content-center'>
											<Icon icon='Apple' size='3x' color='success' />
											<span>Apple</span>
											<small className='text-muted'>Apple Store</small>
										</div>
									</a>

									<a
										style={{'text-decoration': 'none'}}
										className='col-6 p-4 transition-base cursor-pointer bg-light-hover border-bottom border-dark'
									   	onClick={() => copyAndRedirect('GOOGLE')}
									>
										<div className='d-flex flex-column align-items-center justify-content-center'>
											<Icon icon='Google' size='3x' color='primary' />
											<span>Android</span>
											<small className='text-muted'>Google Play</small>
										</div>
									</a>

									<a
										style={{'text-decoration': 'none'}}
										className='col-6 p-4 transition-base cursor-pointer bg-light-hover border-bottom border-dark'
										onClick={generateQrCode}
									>
										<div className='d-flex flex-column align-items-center justify-content-center'>
											<Icon icon='Print' size='3x' color='warning' />
											<span>QrCode</span>
											<small className='text-muted'>Imprimir</small>
										</div>
									</a>

									<a
										style={{'text-decoration': 'none'}}
										className='col-6 p-4 transition-base cursor-pointer bg-light-hover border-bottom border-dark'
										onClick={getExternalScheduleCode}
									>
										<div className='d-flex flex-column align-items-center justify-content-center'>
											<Icon icon='Link' size='3x' color='warning' />
											<span>Agenda</span>
											<small className='text-muted'>Copiar Link</small>
										</div>
									</a>
								</div>
							</DropdownMenu>
						</Dropdown>
					</div>

					<div className='col-auto position-relative'>
						<Popovers trigger='hover' desc='Consultar fatura'>
							<Button
								{...styledBtn}
								icon='AccountBalance'
								onClick={ openInvoicing }
								aria-label='Consultar fatura'
							>
								{
									company && (
										<span className='badge pulse-button'>{ (company.status_paid === 'AUTHORIZATION') ? (company.signature_days <= 0 ? `Fatura será gerada hoje` : `Próxima ${company.signature_days} dias`) : 'Fatura pendente' }</span>
									)
								}

							</Button>
						</Popovers>
						{
							isInvoicing && (
								<Icon
									icon='Circle'
									className='position-absolute start-65 text-success animate__animated animate__heartBeat animate__infinite animate__slower'
								/>
							)
						}
					</div>

					<div className='col-auto'>
						<Button
							color='dark'
							hoverShadow='default'
							isLight
							size='lg'
							icon='Notifications'
							onClick={() => {
								setNewNotification(false);
								setIsOpenNotification(true);
							}}
							aria-label='Notifications'
						>
							<span className='badge pulse-button' style={
								newNotification ? {WebkitAnimation: 'pulseNotification 2s infinite ease-in-out'} : null
							}>{ notifications.length }</span>
						</Button>
					</div>
				</div>
			</div>

			<OffCanvas
				id='notificationCanvas'
				titleId='offcanvasNotifications'
				placement='end'
				isOpen={isOpenNotification}
				style={{ 'width': '350px', 'visibility': 'visible' }}
				setOpen={setIsOpenNotification}>
				<OffCanvasHeader setOpen={setIsOpenNotification}>
					<OffCanvasTitle id='offcanvasNotifications'>Notificações</OffCanvasTitle>
				</OffCanvasHeader>
				<OffCanvasBody>
					{
						notifications.length <= 0 ? 'Nenhuma notificação encontrada.' :
							notifications.map((not, key) => (
								<Alert id={key} isDismissible target='_blank'
									   closeFunction={() => readNotify(not)}
									   icon={ not.icon } key={ key } borderWidth={10}
									   color={ not.color } className='flex-nowrap'>
									<a href={not.link_redirect} style={{'text-decoration': 'none', 'color': 'white', 'white-space': 'pre-line'}} onClick={() => readNotify(not)}>
										<p>Criada: { not.created_at_formatted }</p>
										{ not.data }
									</a>
								</Alert>
							))
					}
				</OffCanvasBody>
			</OffCanvas>
		</Header>
	);
};

export default DashboardHeader;
